var ACC = {};
ACC.giftonGlobal = {
  el: {
    modalPrefix: '[data-modal="{{replaceNumber}}"]',
  },
  init: function () {
    this.events.stepModal();
    this.events.enterInput();
    this.events.agreementTabOnClick();
    this.events.redirectAppstoreOrPlayStore();
  },
  events: {
    stepModal: function () {
      $(document).on('click', '.js-next-step-modal , .js-prev-step-modal', function () {
        var _global = ACC.giftonGlobal;
        var _t = $(this);
        var modalData = _t.closest('.modal').data('modal');
        var closeModal = _global.el.modalPrefix.replace('{{replaceNumber}}', modalData);
        var openModal = _global.el.modalPrefix.replace('{{replaceNumber}}', (_t.hasClass('js-next-step-modal')) ? modalData + 1 : modalData - 1);
        $(closeModal).modal('toggle');
        $(openModal).modal('toggle');
      })
    },
    enterInput: function () {
      $(document).on('keydown', '.enter', function (e) {
        if (e.key === "Enter") {
          var btn = $(this).find('button.js-enter');
          if ($(btn).length > 0 && !$(btn).hasClass('disabled')) {
            $(btn).click();
          }
        }
      })
    },
    agreementTabOnClick: function () {
      $(document).on('click', '.collapsible', function () {
        this.classList.toggle('active');
        var content = this.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + 'px';
        }
      })
    },
    redirectAppstoreOrPlayStore: function () {
      $(document).on('click', '.js-redirect-app', function (e) {
        e.preventDefault();
        if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.gifton.android';
        } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
          window.location.href = 'https://apps.apple.com/tr/app/gifton/id6443988516';
        } else {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.gifton.android';
        }
      })
    }
  }
};
$(document).ready(function () {
  with (ACC.giftonGlobal) {
    init();
  }
})
